import { Action, BrokenEyeIcon, BrokenGclose } from '../../../assets/Icons';
import { CustomPopover } from '../../../components/atoms';
import { ActionCON } from '../../style';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { useQueryClient } from 'react-query';
import { deleteProduct } from '../../../network/services/goods';

export const SellerProductActions = ({ product }: any) => {
  const [value, setValue] = useState<HTMLElement | null>(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (value != null) {
      setValue(null);
    } else {
      setValue(event.currentTarget);
    }
  };

  const onViewDetailsSelect = (id?: any) => {
    navigate(`/seller/products/details/${product}`);
  };

  const { mutateAsync } = useMutationHook(deleteProduct);

  const _delete = async (id: number) => {
    try {
      await mutateAsync(id);
      queryClient.invalidateQueries('seller_products');
    } catch (error: any) {}
  };

  const options = [
    {
      label: 'View details',
      value: 'View details',
      Icon: BrokenEyeIcon,
      handleAction: onViewDetailsSelect
    },

    {
      label: 'Delete',
      value: 'Delete',
      Icon: BrokenGclose,
      color: '#D90429',
      handleAction: _delete
    }
  ];

  return (
    <div>
      <ActionCON aria-describedby="order-action" onClick={event => handleOpen(event)}>
        <Action />
        <CustomPopover item={product} id="order-action" open={value} options={options} />
      </ActionCON>
    </div>
  );
};
