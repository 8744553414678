import { SAddUser, SUpdateUserRole, UploadSellerLogo } from '../../TYPES/api.body';
import {
  ADD_USER,
  CHANGE_USER_ROLE,
  DELETE_SUB_ADMIN_USER,
  GET_ALL_PRODUCT,
  GET_LOCATION,
  GET_SELLER_ROLES,
  GET_SELLER_USERS,
  UPDATE_PROFILE,
  UPLOAD_SELLER_LOGO
} from '../config/endpoints';
import api from '../config/setAuthHeader';

export const getAllProduct = async () => {
  return await api.get(GET_ALL_PRODUCT);
};

export const getSellerUsers = async ({ queryKey }: any) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, payload] = queryKey;
  const { type } = payload;
  const { data } = await api.get(`${GET_SELLER_USERS}/${type}`);
  return data;
};

export const deleteSubAdmin = async (id: string) => {
  const { data } = await api.delete(`${DELETE_SUB_ADMIN_USER}/${id}`);
  return data;
};

export const getRoles = async () => {
  const { data } = await api.get(GET_SELLER_ROLES);
  return data.map((item: { id: number; roleName: string }) => ({ id: item.id, name: item.roleName }));
};

export const getLocation = async () => {
  const { data } = await api.get(GET_LOCATION);
  return data;
};

export const addNewUser = async (formData: SAddUser) => {
  return await api.post(ADD_USER, formData);
};

export const updateUserRole = async (formData: SUpdateUserRole) => {
  const { previousRoleId, sellerRoleId } = formData;
  return await api.put(`${CHANGE_USER_ROLE}/${previousRoleId}`, { sellerRoleId: sellerRoleId });
};

export const uploadSellerLogo = async (payload: UploadSellerLogo) => {
  const { formData } = payload;
  const { data } = await api.post(UPLOAD_SELLER_LOGO, formData);
  return data;
};

export const updateProfile = async (payload: any) => {
  const { data } = await api.patch(UPDATE_PROFILE, payload);
  return data;
};
