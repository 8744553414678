import { ChangePassword, ResponseSuccess, UpdateCustomer, UpdatePicType } from '../../TYPES/api.body';
import {
  ADD_SUBCATEGORY,
  CHANGE_CATEGORY,
  CHANGE_PASSWORD,
  DELETE_ACCOUNT,
  GET_CUSTOMER,
  GET_PROFILE,
  UPDATE_CUSTOMER,
  UPDATE_PIC,
  UPDATE_SUBCATEGORY
} from '../config/endpoints';
import api from '../config/setAuthHeader';

export const getCustomer = async () => {
  const { data } = await api.get(GET_CUSTOMER);
  return data;
};
export const getProfile = async () => {
  const { data } = await api.get(`${GET_PROFILE}`);
  return data;
};

export const updateCustomer = async (formData: UpdateCustomer) => {
  return await api.patch(UPDATE_CUSTOMER, formData);
};

export const changePassword = async (formData: ChangePassword) => {
  const { data } = await api.post(CHANGE_PASSWORD, formData);
  return data;
};
export const changeCategory = async ({ name }: { name: string }) => {
  try {
    const { data } = await api.patch(`${CHANGE_CATEGORY}/?name=${name}`);
    return data;
  } catch (error) {
    console.error('Error changing category:', error);
    throw error;
  }
};
export const updateSubCategory = async ({ id, name }: any) => {
  try {
    const { data } = await api.patch(`${UPDATE_SUBCATEGORY}/${id}/?name=${name}`);
    return data;
  } catch (error) {
    console.error('Error updating subcategory:', error);
    throw error;
  }
};
export const addSubCategory = async ({ id, name }: any) => {
  try {
    const { data } = await api.post(`${ADD_SUBCATEGORY}/?name=${name}&categoryId=${id}`);
    return data;
  } catch (error) {
    console.error('Error adding subcategory:', error);
    throw error;
  }
};

export const updateProfilePic = async (formData: UpdatePicType) => {
  return await api.patch(UPDATE_PIC, formData);
};

export const deleteAccount = async ({ password }: { password: string }): Promise<ResponseSuccess> => {
  const { data } = await api.delete(`${DELETE_ACCOUNT}${password}`);
  return data;
};
