import React, { Dispatch, SetStateAction, useState } from 'react';
import { ImageDisplayCON, ImageUploadCON, UploadPictureCON } from './style';
import { CameraPlusIcon } from '../../../../assets/Icons';
import { COMMONTYPES } from '../../../../TYPES/event.types';
import { Button } from '../../Button/Button';

type UploadPictureProps = {
  setOpenUploadSellerLogoModal: Dispatch<SetStateAction<boolean>>;
  onUpload: (file: File) => void;
  isLoading?: boolean;
};

const UploadPicture = ({ setOpenUploadSellerLogoModal, onUpload, isLoading }: UploadPictureProps) => {
  const pictureRef = React.useRef<HTMLInputElement>(null);
  const [picture, setPicture] = useState<File | null>(null);

  const handleFileRead = (e: COMMONTYPES['inputChange']) => {
    const files = e.target.files;
    files && setPicture(files[0]);
  };

  const handleUploadClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (picture) {
      onUpload(picture);
    }
  };

  const buttonExtraStyles = {
    width: '100%'
  };

  return (
    <UploadPictureCON>
      {picture && (
        <ImageDisplayCON>
          <img src={URL.createObjectURL(picture)} alt="uploaded-logo" />
        </ImageDisplayCON>
      )}
      <ImageUploadCON>
        <div className="image-ref" onClick={() => pictureRef.current?.click()}>
          <CameraPlusIcon />
          <p>Select an Image to upload</p>
        </div>
        <input ref={pictureRef} onChange={handleFileRead} type="file" name="seller-photo" hidden required />
      </ImageUploadCON>

      <Button onClick={handleUploadClick} isLoading={isLoading} disabled={isLoading} extraStyles={buttonExtraStyles}>
        Change
      </Button>
    </UploadPictureCON>
  );
};

export default UploadPicture;
