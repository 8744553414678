import { DeliveryRequestAction } from '../../../actions/Delivery';
import { GreenDot, OrangeDot } from '../../../assets/Icons';
// import { CheckIcon, MessageIcon , GreenDot, OrangeDot } from '../../assets/Icons';
import { formatCurrency } from '../../../utils/currencyFormater';
import { formatDate } from '../../../utils/dateFormater';
import { DeliveryStatusCON } from './style';

export const deliveryFilterOpts = [
  {
    label: 'Delivered',
    value: 'Delivered'
  },
  {
    label: 'Delivery Request',
    value: 'Delivery Request'
  }
];

export const columns = [
  {
    id: 1,
    field: 'orderId',
    headerName: 'Order ID',
    flex: 1,
    minWidth: 200,
    renderCell: ({ row }: any) => <p>{row?.orders?.orderRef}</p>
  },

  {
    id: 2,
    field: 'name',
    headerName: "Customer's Name",
    flex: 2,
    minWidth: 200,
    renderCell: ({ row }: any) => (
      <p>
        {row?.customerName?.firstname} {row?.customerName?.lastname}
      </p>
    )
  },

  {
    id: 3,
    field: 'numberOfItem',
    headerName: 'No of Items ',
    minWidth: 50
  },

  {
    id: 4,
    field: 'price',
    headerName: 'Price ',
    flex: 1,
    minWidth: 200,
    renderCell: ({ row }: any) => <p>{formatCurrency(row?.price)} </p>
  },

  {
    id: 5,
    field: 'deliveryDate',
    headerName: 'Delivery Date',
    flex: 2,
    minWidth: 200,
    renderCell: ({ row }: any) => <p>{formatDate(row?.deliveryDate)} </p>
  },

  {
    id: 6,
    field: 'deliveryType',
    headerName: 'Delivery Type',
    flex: 2,
    minWidth: 200
  },

  {
    id: 7,
    field: 'logisticsCompany',
    headerName: 'Logistics',
    flex: 1,
    minWidth: 100,
    renderCell: ({ row }: any) => <p>{row?.logisticCompany?.name}</p>
  },

  {
    id: 8,
    field: 'status',
    headerName: 'status',
    flex: 2,
    minWidth: 150,
    renderCell: ({ row }: any) => (
      <DeliveryStatusCON status={row.status}>
        {row.status.includes('completed') ? <GreenDot /> : <OrangeDot />}
        <p>{row.status}</p>
      </DeliveryStatusCON>
    )
  }
];

export const deliveryRequestColumns = [
  {
    id: 1,
    field: 'orderId',
    headerName: 'Order ID',
    flex: 1,
    minWidth: 200,
    renderCell: ({ row }: any) => <p>{row?.orders?.orderRef}</p>
  },

  {
    id: 2,
    field: 'name',
    headerName: "Customer's Name",
    flex: 2,
    minWidth: 200,
    renderCell: ({ row }: any) => (
      <p>
        {row?.customerName?.firstname} {row?.customerName?.lastname}
      </p>
    )
  },

  {
    id: 3,
    field: 'numberOfItem',
    headerName: 'No of Items ',
    minWidth: 50
  },

  {
    id: 4,
    field: 'price',
    headerName: 'Price ',
    flex: 1,
    minWidth: 200,
    renderCell: ({ row }: any) => <p>{formatCurrency(row?.price)} </p>
  },

  {
    id: 5,
    field: 'deliveryDate',
    headerName: 'Delivery Date',
    flex: 2,
    minWidth: 200,
    renderCell: ({ row }: any) => <p>{formatDate(row?.deliveryDate)} </p>
  },

  {
    id: 6,
    field: 'deliveryType',
    headerName: 'Delivery Type',
    flex: 2,
    minWidth: 200
  },

  {
    id: 7,
    field: 'action',
    headerName: 'Action',
    flex: 0,
    minWidth: 100,
    renderCell: ({ row }: any) => <DeliveryRequestAction />
  }
];

export const deliveryRequests = [
  {
    id: 83,
    customerName: {
      id: 156,
      firstname: 'Joel',
      lastname: 'Joshua'
    },
    orders: {
      id: 114,
      orderRef: '#MAWUOERE23',
      cartSize: 0,
      orderReturned: false
    },
    deliveryDate: '2024-01-18T16:00:15.262848',
    price: 7308.4,
    numberOfItem: 1,
    deliveryType: 'delivery',
    logisticCompany: {
      id: 17,
      name: 'Fedex',
      amount: 6008,
      logisticFee: 300.40000000000003,
      totalAmount: 6308.4,
      carrierId: '52b60e07-2cfe-408d-836c-75ecc08a550d',
      carrierLogo: 'https://delivery-staging.apiideraos.com/partners/redstar.png',
      estimatedDaysOfDelivery: 'Estimated days: 7 to 14 days – Doorstep',
      redisKey: '5c1b03f2-e1be-4d77-b3d2-4d1b3dec668a',
      ratesKey: null,
      eta: 14,
      status: null
    },
    status: 'PENDING'
  }
];
