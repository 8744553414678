import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { AiFillEdit } from 'react-icons/ai';
import { addSubCategory, getProfile, updateSubCategory } from '../../../network/services/profile';
import { useQuery, useQueryClient } from 'react-query';

const EditSubCategories = () => {
  const queryClient = useQueryClient();
  const [subCategoryName, setSubCategoryName] = useState('');
  const [subCategories, setSubCategories] = useState<string[]>([]);
  const [editingIndex, setEditingIndex] = useState<any>(null); // Track which subcategory is being edited
  const [editingName, setEditingName] = useState<string>(''); // Track the new name for editing

  const { data: profileData, isLoading: isProfileLoading } = useQuery(['get-profile'], getProfile);

  const profile = profileData?.data ?? [];
  const categoryId = profile?.categoryId;

  const { mutateAsync: addMutateAsync, isLoading: addIsLoading } = useMutationHook(addSubCategory, 'SUBCATEGORY_ADD');
  const { mutateAsync: updateMutateAsync, isLoading: updateIsLoading } = useMutationHook(
    updateSubCategory,
    'SUBCATEGORY_UPDATE'
  );

  const handleAddSubCategory = async () => {
    if (!subCategoryName.trim() || !categoryId) return;

    try {
      const data = await addMutateAsync({ name: subCategoryName.trim(), id: categoryId });
      if (data) {
        queryClient.invalidateQueries('get-profile');
        setSubCategoryName(''); // Clear the input after successful addition
      }
    } catch (error) {
      console.error('Error adding subcategory:', error);
    }
  };

  const handleEditClick = (index: number, name: string) => {
    setEditingIndex(index);
    setEditingName(name || '');
  };

  const handleUpdateSubCategory = async (index: number) => {
    if (!editingName.trim() || !categoryId) return;

    try {
      const data = await updateMutateAsync({ name: editingName.trim(), id: index });

      if (data) {
        queryClient.invalidateQueries('get-profile');

        // Reset after successful update
        setEditingIndex(null);
        setEditingName(''); // Clear the editing name
      }
    } catch (error) {
      console.error('Error updating subcategory:', error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubCategoryName(e.target.value);
  };

  const handleEditingNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditingName(e.target.value);
  };

  return (
    <div>
      {profile?.length !== 0 && (
        <div className="grid gap-y-2">
          {profile?.subCategoriesMapList?.map((sub: any, index: number) => (
            <div className="flex justify-between items-center" key={sub?.Id}>
              {editingIndex === sub?.Id ? (
                <TextField
                  value={editingName}
                  onChange={handleEditingNameChange}
                  variant="filled"
                  className="rounded-xl h-fit py-0"
                />
              ) : (
                <p className="font-medium">{sub?.Subcategory}</p>
              )}
              {editingIndex === sub?.Id ? (
                <Button
                  onClick={() => handleUpdateSubCategory(editingIndex)}
                  disabled={updateIsLoading || !editingName.trim() || !categoryId}
                  className="text-red-600 text-lg cursor-pointer">
                  {updateIsLoading ? 'Saving...' : 'Save'}
                </Button>
              ) : (
                <AiFillEdit
                  className="text-red-600 text-lg cursor-pointer"
                  onClick={() => handleEditClick(sub?.Id, sub?.SubCategory)} // Pass only SubCategory
                />
              )}
            </div>
          ))}
        </div>
      )}
      <p className="font-semibold text-center my-6">Add Sub Category:</p>
      <TextField
        label="Subcategory Name"
        variant="filled"
        value={subCategoryName}
        onChange={handleInputChange}
        fullWidth
        className="rounded-xl h-fit py-0"
      />
      <button
        onClick={handleAddSubCategory}
        disabled={addIsLoading || !subCategoryName.trim() || !categoryId}
        className={`mt-6 w-full py-4 text-center rounded-xl text-white ${
          !subCategoryName.trim() || addIsLoading || !categoryId ? 'bg-blue-800/30' : 'bg-blue-800'
        }`}>
        {addIsLoading ? 'Adding...' : 'Add Subcategory'}
      </button>
    </div>
  );
};

export default EditSubCategories;
