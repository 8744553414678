import { Box, styled } from '@mui/material';
import { COLORS } from '../../../theme/color';

export const ProfilPicCon = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: 0 auto 40px;

  p {
    color: ${COLORS.SecBlack};
    font-size: 16px;
    font-family: Outfit;
    line-height: 24px;
  }
  .img {
    width: 100px;
    height: 100px;
    position: relative;
    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
    }
    svg {
      transform: scale(1.1);
    }
  }
  .edit {
    position: absolute;
    bottom: 0;
    right: 5px;
  }
  input {
    display: none;
  }
`;
