import { Route, Routes } from 'react-router-dom';
import { Spacer } from '../../components/atoms';
import {
  // AddProduct,
  OrderList,
  CustomerSupport,
  Delivery,
  FulfilmentList,
  LoanList,
  LoanSettings
  // SignUp
} from '../../pages';
import {
  ADD_PRODUCT,
  PRODUCTS,
  ORDERS,
  CUSTOMERSUPPORT,
  DELIVERIES,
  USERS,
  FUFILLMENT,
  LOANS,
  LOAN_SETTINGS,
  SETTINGS,
  DASHBOARD,
  SELLER_WALLET,
  PRODUCT_DETAILS,
  SELLER_DELIVERY,
  SELLER_LOGISTICS
} from '../routes';
import { Container, MainGridCon } from '../styles';
import {
  ProductDetails,
  SellerDashboard,
  SellerDelivery,
  SellerProductList,
  SellerUserList,
  SellerWallet,
  SellerLogisticsList
} from '../../pages/Seller';
import { SellerAddProduct } from '../../pages/Seller/Product/AddProduct';
import { Settings } from '../../pages/Seller/Settings';

const SellerRoutes = () => {
  return (
    <Container>
      <MainGridCon>
        <Routes>
          <Route path={`${DASHBOARD}`} element={<SellerDashboard />} />
          <Route path={PRODUCTS} element={<SellerProductList />} />
          <Route path={SELLER_DELIVERY} element={<SellerDelivery />} />
          <Route path={SELLER_LOGISTICS} element={<SellerLogisticsList />} />
          <Route path={SELLER_WALLET} element={<SellerWallet />} />
          <Route path={ADD_PRODUCT} element={<SellerAddProduct />} />
          <Route path={`${PRODUCT_DETAILS}/:id`} element={<ProductDetails />} />
          <Route path={ORDERS} element={<OrderList />} />
          <Route path={CUSTOMERSUPPORT} element={<CustomerSupport />} />
          <Route path={DELIVERIES} element={<Delivery />} />
          <Route path={USERS} element={<SellerUserList />} />
          <Route path={FUFILLMENT} element={<FulfilmentList />} />
          <Route path={LOANS} element={<LoanList />} />
          <Route path={LOAN_SETTINGS} element={<LoanSettings />} />
          <Route path={SETTINGS} element={<Settings />} />
        </Routes>
      </MainGridCon>
      <Spacer height={100} />
    </Container>
  );
};

export default SellerRoutes;
