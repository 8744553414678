import { styled } from '@mui/material';
import { COLORS } from '../../../theme/color';

export const DeliveryCON = styled('div')``;

export const DeliveryTableCON = styled('div')`
  background: ${COLORS.White};
`;

export const TableHeaderCON = styled('div')`
  padding: 32px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DeliveryStatusCON = styled('div')<{ status?: string }>`
  background: ${({ status }) => (status?.includes('completed') ? '#F0F5EA' : '#FFF6C7')};
  color: ${({ status }) => (status?.includes('completed') ? '#669F2A' : '#F79009')};
  display: flex;
  padding: 4px 16px;
  align-items: center;
  border-radius: 8px;
  gap: 8px;

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    text-transform: capitalize;
    line-height: 24px;
  }
`;

export const ActionCON = styled('div')`
  cursor: pointer;
`;

export const OrderStatusCON = styled('div')<{ status?: string }>`
  background: ${({ status }) =>
    status?.toLocaleLowerCase().includes('Out for delivery')
      ? '#FFF6C7'
      : status?.toLocaleLowerCase().includes('confirmed')
      ? '#FFF6C7'
      : '#2F4DC4'};
  color: ${({ status }) =>
    status?.toLocaleLowerCase().includes('Out for delivery')
      ? COLORS.MainBlue
      : status?.toLocaleLowerCase().includes('confirmed')
      ? '#F79009'
      : '##27AE60'};
  display: flex;
  padding: 4px 16px;
  align-items: center;
  border-radius: 8px;
  gap: 8px;

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    text-transform: capitalize;
    line-height: 24px;
  }
`;
