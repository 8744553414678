import { AAddLogistics, UpdateLogistics } from '../../../TYPES/api.body';
import {
  ADMIN_ADD_LOGISTICS,
  ADMIN_DELETE_LOGISTICS,
  ADMIN_GET_ALL_LOGISTICS,
  ADMIN_UPDATE_LOGISTICS
} from '../../config/endpoints';
import api from '../../config/setAuthHeader';

export const addLogistics = async (formData: AAddLogistics) => {
  return await api.post(ADMIN_ADD_LOGISTICS, formData);
};

export const updateLogistics = async (formData: UpdateLogistics) => {
  return await api.patch(`${ADMIN_UPDATE_LOGISTICS}/${formData.id}`, formData);
};

export const getLogistics = async () => {
  const { data } = await api.get(`${ADMIN_GET_ALL_LOGISTICS}`);
  return data;
};

export const deleteLogistics = async (id: number) => {
  const { data } = await api.delete(`${ADMIN_DELETE_LOGISTICS}/${id}`);
  return data;
};
