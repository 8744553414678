import { Badge, BadgeProps, styled } from '@mui/material';
import { COLORS } from '../../../theme/color';
import { breakpoints } from '../../../theme/theme';

export const StickyNotification = styled('div')`
  position: sticky;
  top: 70px; /* Adjust this value to match the height of your TopNavBarCon */
  background-color: ${COLORS.MainRed}; /* Adjust the color as needed */
  padding: 1.2rem 0.5rem; /* Adjust the padding as needed */
  text-align: center;
  color: ${COLORS.White}; /* Adjust the text color as needed */
  z-index: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adjust the shadow as needed */

  @media (max-width: ${breakpoints.md}px) {
    top: 64px;
  }
`;
export const TopNavBarCon = styled('div')`
  background: ${COLORS.White};
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding: 0.8rem 6rem 0.8em 0px;
  z-index: 2;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  @media (max-width: ${breakpoints.sm}px) {
    padding: 0.8rem 1rem;
  }
  .content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex: 1;

      .mobile {
        display: flex;
        align-items: center;
      }

      .logo {
        cursor: pointer;
        max-height: 100%;
        margin-left: 2px;
      }

      @media (max-width: ${breakpoints.md}px) {
        flex: none;
        .logo {
          transform: scale(0.85);
        }
      }
    }

    .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      /* flex: 1; */

      h3 {
        font-weight: 400;
        font-size: 1rem;
        color: ${COLORS.MainBlack};
        display: flex;
        justify-content: start;
        align-items: center;
        max-width: 190px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .link {
        font-weight: 400;
        font-size: 1rem;
        color: ${COLORS.MainBlack};
        text-decoration: none;
        /* svg {
          transform: scale(0.95);
        } */
        display: flex;
        align-items: center;
        h3 {
          font-size: 18px;
          font-weight: 700;
          color: ${COLORS.DarkBlue};
        }
        p {
          font-size: 14px;
          font-weight: 400;
          color: ${COLORS.SecBlack};
        }
      }
      .avatar {
        cursor: pointer;
        margin: 0 15px 0 50px;
        img {
          height: 50px;
          width: 50px;
          border-radius: 68px;
        }
      }
      @media (max-width: ${breakpoints.sm}px) {
        .cart {
          transform: scale(0.9);
        }
        .avatar {
          margin: 0 15px 0 10px;

          img {
            height: 30px;
            width: 30px;
            border-radius: 60px;
          }
        }
      }
    }
  }
`;

export const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: 0,
    left: theme.breakpoints.down('md') ? 0 : 7,
    backgroundColor: COLORS.MainRed,
    color: COLORS.White,
    width: '3px',
    fontSize: '10px'
  }
}));
