import { Box, Select, styled } from '@mui/material';
import { COLORS } from '../../../theme/color';

export const SettingsInfoCON = styled('div')`
  h3 {
    color: #000;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Outfit;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    padding-bottom: 20px;
  }
`;

export const ImageCON = styled('div')`
  div {
    img {
      width: 92px;
      height: 92px;
      flex-shrink: 0;
      border-radius: 100%;
      object-fit: cover;
    }
    display: flex;
    align-items: center;
    gap: 31px;
  }
`;

export const CoverImageCON = styled('div')`
  div {
    width: 100%;
    img {
      width: 100%;
      height: 150px;
      flex-shrink: 0;
      border-radius: 8px;
      object-fit: cover;
    }
  }
`;
export const underlineButton = {
  textDecoration: 'underline',
  color: `${COLORS.MainBlue}`,
  fontSize: '14px',
  fontWeight: '500',
  border: 'none',
  background: '#fff',
  width: '107px',
  padding: '0px'
};
export const UploadBtnExtraStyles = {
  background: '#fff',
  border: `0.6px solid ${COLORS.MainBlue}`,
  color: `${COLORS.MainBlue}`,
  borderRadius: '8px',
  width: '107px',
  height: '41px',
  fontSize: '16 px',
  fontWeight: '400'
};

export const RemoveBtnExtraStyles = {
  background: '#fff',
  border: `0.6px solid ${COLORS.MainBlue}`,
  color: `${COLORS.MainBlack}`,
  borderRadius: '8px',
  width: '107px',
  height: '41px',
  fontSize: '16px',
  fontWeight: '400'
};

export const DeleteButtonExtraStyles = {
  background: '#fff',
  color: '#eb2424',
  fontSize: '20px',
  textAlign: 'right',
  width: '200px',
  fontWeight: '500'
};

export const InputCON = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin: 0.5rem 0;
  }
`;

export const FormCON = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  padding: 30px 0;
`;

export const BankAccountsListCON = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
  padding: 10px;

  .bank-details-grid {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: 1rem;
  }

  .bank-detail {
    grid-column: span 11 / span 11;
  }

  .delete-icon {
    grid-column: span 1 / span 1;
    cursor: pointer;
  }
`;

export const DeleteAccountCON = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  padding: 30px 0;

  p {
    color: rgba(0, 0, 0, 0.5);
    font-size: 15px;
    font-weight: 300;
  }
`;

export const SubmitButtonCON = styled(Box)`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  align-items: end;

  button {
    width: 200px;
    border-radius: 8px;
    height: 47px;
  }
`;

export const VerifyOTpCON = styled(Box)`
  display: flex;
  margin-top: 20px;
  flex-direction: column;

  p {
    color: rgba(0, 0, 0, 0.5);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Outfit;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    padding-top: 10px;

    span {
      color: #000;
      font-weight: 500;
      line-height: 24px;
    }
  }

  .send-email {
    color: ${COLORS.MainBlue};
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Outfit;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  button {
    margin-top: 40px;
    width: 200px;
    border-radius: 8px;
    height: 47px;
  }
`;

export const InputExtraStyles = {
  borderRadius: '8px',
  border: '1px solid rgba(47, 77, 196, 0.20)',
  background: 'rgba(47, 77, 196, 0.10)',
  color: 'rgba(0, 0, 0, 0.50)'
};

export const InputDisabledExtraStyles = {
  borderRadius: '8px',
  border: '1px solid rgba(47, 77, 196, 0.20)',
  background: 'rgba(47, 77, 196, 0.10)',
  color: '#FF5F00'
};
export const CustomSelect = styled(Select)({
  width: '100%',
  height: '56px',
  alignItems: 'center',
  padding: '12px',
  gap: '10px',
  borderRadius: '4px',
  background: 'transparent',
  border: '1px solid #E6E6E6',
  opacity: '0.8',
  '& .MuiInputBase-input': {
    borderRadius: 4,
    backgroundColor: '#fff',
    // border: '1px solid #dbdce9',
    fontFamily: 'Outfit'
  },
  '.MuiInputBase-input': {
    background: 'transparent'
  }
});
