import {
  DashboardIcon,
  ProductIcon,
  DeliveryIcon,
  AdminSettingsIcon,
  AdminUsersIcon,
  WalletIcon,
  OrderIcon
  // AddIcon,
  // UploadIcon
} from '../../assets/Icons';
import { navLinkProps } from '../../components/atoms/NavLinks/NavLinks';

export const sellerNavs: navLinkProps[] = [
  {
    label: 'Dashboard',
    to: '/seller/dashboard',
    Icon: DashboardIcon
  },

  // {
  //   label: 'Users',
  //   to: '/seller/users',
  //   Icon: AdminUsersIcon
  // },

  {
    label: 'Products',
    to: '/seller/products',
    Icon: ProductIcon
  },

  {
    label: 'Wallet',
    to: '/seller/wallet',
    Icon: WalletIcon
  },

  {
    label: 'Orders',
    to: '/seller/orders',
    Icon: OrderIcon
  },
  {
    label: 'Delivery',
    to: '/seller/delivery',
    Icon: DeliveryIcon
  },
  {
    label: 'Logistics',
    to: '/seller/logistics',
    Icon: DeliveryIcon
  },

  {
    label: 'Settings',
    to: 'seller/settings',
    Icon: AdminSettingsIcon
  }
];
