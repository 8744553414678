import { Button, InputField, Spacer } from '../../../../components/atoms';
import { createRef, useEffect, useMemo, useRef, useState } from 'react';
import { APIUpload, Modal, TextEditor } from '../../../../components/molecules';
import { UploadIcon } from '../../../../assets/Icons';

import {
  AddProductCON,
  FormCON,
  InputCon,
  extraStylesInput,
  AltInput,
  ImageUploadCON,
  extraStylesButton,
  TextEditorCON,
  ActionsCon,
  ManualBtnExtraStyles,
  CSVBtnExtraStyles,
  // CsvUploadCon,
  CustomSelect
} from './style';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { COMMONTYPES } from '../../../../TYPES/event.types';
import { useQuery } from 'react-query';
import { useQueryClient } from 'react-query';
import { getAllcategories, getSubcategories, manualProductUpload } from '../../../../network/services/goods';
// import { csvUpload, getAllcategories, getSubcategories, manualProductUpload } from '../../../../network/services/goods';
import { useMutationHook } from '../../../../network/mutations/useMutationHook';
import { CsvProductUpload } from './csv';
import { ProductUpload } from '../../../../TYPES/api.body';
import { useNavigate } from 'react-router-dom';
import ImageDropzone from '../../../../components/molecules/ImageDropzone';
import { getProfile } from '../../../../network/services/profile';

const INITIAL_STATE: ProductUpload = {
  subCategoryId: 1,
  productName: '',
  price: '',
  quantityAvailable: '',
  minimumOrderQuantity: '',
  bulkOrderPrice: '',
  barCode: '',
  variation: '',
  size: '',
  description: '',
  productImages: []
};

export interface ProductUploadResponse {
  status: string;
  description: string;
}

interface ImageState {
  image: File | string | null;
}

const INITIAL_IMAGE_STATES: ImageState[] = [
  { image: null },
  { image: null },
  { image: null },
  { image: null },
  { image: null }
];

export const SellerAddProduct = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(INITIAL_STATE);

  const { data: profileData } = useQuery(['get-profile'], getProfile);

  const profile = profileData?.data ?? [];

  const subCategories = profile?.subCategoriesMapList;

  const [imageStates, setImageStates] = useState(INITIAL_IMAGE_STATES);
  const inputRefs = useMemo(
    () =>
      Array(INITIAL_IMAGE_STATES.length)
        .fill(0)
        .map(i => createRef<HTMLInputElement>()),
    []
  );

  const [apiIsOpen, setApiIsOpen] = useState<boolean>(false);
  const [csvIsOpen, setCsvIsOpen] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const onSelectChange = (e: SelectChangeEvent<unknown>) => {
    const { name, value } = e.target;
    setFormData(formData => ({ ...formData, [name]: value }));
  };

  const onInputChange = (e: COMMONTYPES['inputChange']) => {
    e.stopPropagation();
    const { name, value } = e.target;
    setFormData(formData => ({ ...formData, [name]: value }));
  };

  const onTextEditorChange = (value: string) => {
    setFormData(formData => ({ ...formData, description: value }));
  };

  const selectClick = (e: COMMONTYPES['divClick']) => e.stopPropagation();

  const handleFileRead = (e: COMMONTYPES['inputChange'], index: number) => {
    const file = e.target.files?.[0];
    if (file) {
      setImageStates(prevStates => {
        const updatedStates = [...prevStates];
        updatedStates[index] = { image: file };
        return updatedStates;
      });

      setFormData(formData => ({
        ...formData,
        productImages: [...formData.productImages, file] as File[]
      }));
    }
  };

  const handleFileDelete = (index: number) => {
    setImageStates(prevStates => {
      const updatedStates = [...prevStates];
      updatedStates[index] = { image: null };
      return updatedStates;
    });

    // Remove the deleted image from the formData.productImages array
    setFormData(formData => ({
      ...formData,
      // @ts-ignore
      productImages: formData.productImages.filter((_: File | string, i: number) => i !== index) as File[]
    }));
  };

  const { mutateAsync, isLoading } = useMutationHook(manualProductUpload, 'PRODUCT_UPLOAD');

  const _manualProductUpload = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const payload = new FormData();

    // Append each field from formData to the payload as key-value pairs
    payload.append('subCategoryId', formData.subCategoryId.toString());
    payload.append('productName', formData.productName);
    payload.append('price', formData.price);
    payload.append('quantityAvailable', formData.quantityAvailable);
    payload.append('minimumOrderQuantity', formData.minimumOrderQuantity);
    payload.append('bulkOrderPrice', formData.bulkOrderPrice);
    payload.append('barCode', formData.barCode);
    payload.append('variation', formData.variation || '');
    payload.append('size', formData.size || '');
    payload.append('description', formData.description);

    // Append each product image to the payload
    formData.productImages.forEach((imageFile: any) => {
      payload.append('productImages', imageFile);
    });

    try {
      const data = (await mutateAsync(payload)) as unknown as ProductUploadResponse;

      if (data.status === 'success') {
        queryClient.invalidateQueries('seller_products');
        navigate('/seller/products');
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  const disabled = !formData.productImages.length || !formData.productName || !formData.price || !formData.description;

  return (
    <AddProductCON>
      {/* <CustomBreadcrumb firstLabel="Products" secondLabel="Add new products" /> */}
      <ActionsCon>
        <Button extraStyles={ManualBtnExtraStyles}>
          Manual <UploadIcon />
        </Button>

        <Button onClick={() => setCsvIsOpen(true)} extraStyles={CSVBtnExtraStyles}>
          Upload CSV <UploadIcon />
        </Button>
        <Button onClick={() => setApiIsOpen(true)} extraStyles={CSVBtnExtraStyles}>
          Via API
        </Button>
      </ActionsCon>
      <FormCON>
        <AltInput>
          <InputCon>
            <span>Product Name </span>
            <InputField
              type="text"
              name="productName"
              placeholder="Name of Product"
              onChange={onInputChange}
              extraStyles={extraStylesInput}
              required={true}
              autoComplete="new-passowrd"
            />
          </InputCon>

          <InputCon>
            <span>Price</span>
            <InputField
              type="text"
              name="price"
              onChange={onInputChange}
              placeholder="Price of Product"
              extraStyles={extraStylesInput}
              required={true}
              // error={formError.password}

              autoComplete="new-passowrd"
            />
          </InputCon>
        </AltInput>

        {/* <InputCon>
          <span>Category</span>
          <CustomSelect
            name="categoryId"
            value={formData.productDetails.categoryId}
            onChange={onSelectChange}
            onClick={selectClick}>
            {categories?.map((item: any, index: any) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </CustomSelect>
        </InputCon> */}

        <AltInput>
          <InputCon>
            <span>Sub category</span>
            <CustomSelect
              name="subCategoryId"
              value={formData.subCategoryId}
              onChange={onSelectChange}
              onClick={selectClick}>
              {subCategories?.map((item: any, index: any) => (
                <MenuItem key={index} value={item.Id}>
                  {item.Subcategory}
                </MenuItem>
              ))}
            </CustomSelect>
          </InputCon>
        </AltInput>

        <AltInput>
          <InputCon>
            <span>Quantity Available</span>
            <InputField
              type="text"
              name="quantityAvailable"
              onChange={onInputChange}
              placeholder="Quantity Available"
              extraStyles={extraStylesInput}
              required={true}
              autoComplete="new-passowrd"
            />
          </InputCon>

          <InputCon>
            <span>Minimum Order Quantity (MOQ)</span>
            <InputField
              type="text"
              name="minimumOrderQuantity"
              onChange={onInputChange}
              placeholder="Least order number"
              extraStyles={extraStylesInput}
              required={true}
              autoComplete="new-passowrd"
            />
          </InputCon>
        </AltInput>

        <AltInput>
          <InputCon>
            <span>Bulk Order Price at MOQ</span>
            <InputField
              type="text"
              name="bulkOrderPrice"
              placeholder="Bulk order price when item hits MOQ"
              extraStyles={extraStylesInput}
              onChange={onInputChange}
              required={true}
              autoComplete="new-passowrd"
            />
          </InputCon>

          <InputCon>
            <span>Bar Code</span>
            <InputField
              type="text"
              name="barCode"
              placeholder="Bulk order price when item hits MOQ"
              extraStyles={extraStylesInput}
              required={true}
              onChange={onInputChange}
              autoComplete="new-passowrd"
            />
          </InputCon>
        </AltInput>
        {/* <AltInput>
          <InputCon>
            <span>Sub-level categories</span>
            <CustomSelect
              name="subLevelCategoryId"
              // value={formData.productDetails.subCategoryId}
              onChange={onSelectChange}
              onClick={selectClick}>
              <MenuItem value="sub-level-1">Sub-level 1</MenuItem>
              <MenuItem value="sub-level-2">Sub-level 2</MenuItem>
            </CustomSelect>
          </InputCon>
        </AltInput> */}
        <AltInput>
          <InputCon>
            <span>Variations</span>
            <InputField
              type="text"
              name="variation"
              placeholder="Product variations"
              extraStyles={extraStylesInput}
              onChange={onInputChange}
              required={true}
              autoComplete="new-passowrd"
            />
          </InputCon>

          <InputCon>
            <span>Size</span>
            <InputField
              type="text"
              name="size"
              placeholder="Enter size in kg"
              extraStyles={extraStylesInput}
              required={true}
              onChange={onInputChange}
              autoComplete="new-passowrd"
            />
          </InputCon>
        </AltInput>
        <Spacer height="1rem" />
        <TextEditorCON>
          <span>Description</span>
          <TextEditor onChange={onTextEditorChange} />
        </TextEditorCON>

        <Spacer height="2rem" />

        <ImageUploadCON>
          <span>Product Images</span>
          <div className="images-con">
            <div className="primary-image-con">
              <ImageDropzone
                handleFileRead={e => handleFileRead(e, 0)}
                handleFileDelete={() => handleFileDelete(0)}
                inputRef={inputRefs[0]}
                image={imageStates[0].image}
              />
            </div>
            <div className="secondary-images-con">
              {imageStates.slice(1).map((imageState, index) => (
                <ImageDropzone
                  key={index}
                  handleFileRead={e => handleFileRead(e, index + 1)}
                  handleFileDelete={() => handleFileDelete(index + 1)}
                  inputRef={inputRefs[index + 1]}
                  image={imageState.image}
                  small
                />
              ))}
            </div>
          </div>
        </ImageUploadCON>
        <Spacer height="4rem" />
        <InputCon>
          <Button
            onClick={_manualProductUpload}
            isLoading={isLoading}
            disabled={disabled}
            extraStyles={extraStylesButton}>
            Add Product
          </Button>
        </InputCon>
      </FormCON>

      {apiIsOpen && (
        <Modal maxWidth="500px" title="Via API - Odoo.v16" onModalClose={() => setApiIsOpen(false)}>
          <APIUpload />
        </Modal>
      )}

      {csvIsOpen && <CsvProductUpload onModalClose={() => setCsvIsOpen(false)} />}
    </AddProductCON>
  );
};
