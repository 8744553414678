import React, { Dispatch, SetStateAction, useState } from 'react';
import { Modal } from '../Modal/Modal';
import { WalletModalCon, InputCon, extraStylesInput } from './styles';
import { COMMONTYPES } from '../../../TYPES/event.types';
import { Button, CustomSelect, InputField, Spacer } from '../../atoms';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { BankDetails, WithdrawWallet } from '../../../TYPES/api.body';
import { getBankDetails, initiateWithdrawWallet, getSellerWalletBalance } from '../../../network/services/wallet';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../../store/keys';
import { DropdownIcon2 } from '../../../assets/Icons';
import { MenuItem, SelectChangeEvent } from '@mui/material';

type Props = {
  withdrawFormData: WithdrawWallet;
  setOpenWithdrawModal: Dispatch<SetStateAction<boolean>>;
  handleInitiateWithdrawSuccess: () => void;
  setWithdrawFormData: React.Dispatch<React.SetStateAction<WithdrawWallet>>;
};

type BankDetailsPartial = Omit<BankDetails, 'password'>;

const INITIAL_STATE: BankDetailsPartial[] = [
  {
    bankName: '',
    accountName: '',
    accountNumber: ''
  }
];

function WithdrawWalletModal({
  withdrawFormData,
  setWithdrawFormData,
  setOpenWithdrawModal,
  handleInitiateWithdrawSuccess
}: Props) {
  const [bankDetails, setBankDetails] = useState<BankDetailsPartial[]>(INITIAL_STATE);

  const { data: sellerWalletBalance } = useQuery('wallet_balance', getSellerWalletBalance);
  const walletBalance: any = sellerWalletBalance?.data?.wallet_balance ?? null;

  const onSelectChange = (e: SelectChangeEvent<unknown>) => {
    const { name, value } = e.target;
    setWithdrawFormData(formData => ({ ...formData, [name]: value }));
  };

  const onInputChange = (e: COMMONTYPES['inputChange']) => {
    const { name, value } = e.target;

    setWithdrawFormData(formData => ({ ...formData, [name]: value }));
  };
  const selectClick = (e: COMMONTYPES['divClick']) => e.stopPropagation();

  useQuery({
    queryKey: QUERY_KEYS.SELLER_BANK_DETAILS,
    queryFn: getBankDetails,
    onSuccess: ({ data }) => {
      setBankDetails(data);
    }
  });

  const { mutateAsync, isLoading } = useMutationHook(initiateWithdrawWallet, 'INITIATE_WALLET_WITHDRAWAL');

  const _handleFundWallet = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    try {
      const data = await mutateAsync({ ...withdrawFormData, amount: Number(withdrawFormData.amount) });

      // @ts-ignore
      if (data.status.toLowerCase() === 'success') {
        handleInitiateWithdrawSuccess();
      }
    } catch (error: any) {}
  };

  const onModalClose = () => {
    setOpenWithdrawModal(false);
  };

  const disabled =
    !withdrawFormData.amount || !withdrawFormData.accountNumber || withdrawFormData.amount > walletBalance;

  return (
    <Modal title="Withdraw Funds" maxWidth="500px" onModalClose={onModalClose}>
      <WalletModalCon>
        <InputCon>
          <span>Bank Name</span>
          <CustomSelect
            IconComponent={DropdownIcon2}
            name="accountNumber"
            value={withdrawFormData.accountNumber}
            onChange={onSelectChange}
            placeholder="Select Bank Details"
            onClick={selectClick}>
            {bankDetails.map(bankDetail => (
              <MenuItem key={bankDetail.accountNumber} value={bankDetail.accountNumber}>
                {bankDetail.bankName} - {bankDetail.accountNumber}
              </MenuItem>
            ))}
          </CustomSelect>
        </InputCon>
        <InputCon>
          <span>Amount</span>
          <InputField
            type="number"
            extraStyles={extraStylesInput}
            placeholder="Enter amount to withdraw"
            onChange={onInputChange}
            name="amount"
            value={withdrawFormData.amount}
          />
          {Number(withdrawFormData.amount) > walletBalance && (
            <span style={{ color: 'red' }}>Amount exceeds wallet balance</span>
          )}
        </InputCon>
        <Spacer height="32px" />

        <Button onClick={_handleFundWallet} isLoading={isLoading} disabled={disabled}>
          Continue{' '}
        </Button>
      </WalletModalCon>
    </Modal>
  );
}

export default WithdrawWalletModal;
