import { Theme, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Menu2Icon } from '../../../assets/Icons';

import { LOGIN } from '../../../navigation/routes';
import { userAtom, userIsLoggedIn } from '../../../store/atoms/userAtom';
// import { Spacer } from '../../atoms';
import { Logo, Spacer } from '../../atoms';
// import { Search } from '../SearchBox';
import { StickyNotification, TopNavBarCon } from './styles';
// import { userRoleAtom } from '../../../store/atoms/userAtom';
import { useState } from 'react';
import { SideBar } from '../SideBar/SideBar';
// import { useQuery } from 'react-query';
// import { getCustomer } from '../../../network/services/profile';
import SellerAvatar from '../../../assets/images/seller-avatar.jpeg';
import { useQuery } from 'react-query';
import { getProfile } from '../../../network/services/profile';

export const TopNavBar = () => {
  const [side, setSide] = useState(false);
  const isLogged = useRecoilValue(userIsLoggedIn);
  // const userRole = useRecoilValue(userRoleAtom);
  const user = useRecoilValue(userAtom);

  const { data: profileData } = useQuery(['get-profile'], getProfile);

  const profile = profileData?.data ?? [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const { data, isFetchedAfterMount } = useQuery('profile', getCustomer, {
  //   staleTime: Infinity
  // });
  // const profile = data || null;

  const md = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <>
      <TopNavBarCon>
        <div className="content">
          <div className="left">
            {md && (
              <>
                <div className="mobile">
                  <Menu2Icon onClick={() => setSide(true)} />
                  <Logo />
                </div>

                <Spacer width={8} />
              </>
            )}
            {!md && <Spacer width={50} />}
            {/* {!md && <Search onChange={() => {}} name="search" placeholder="Search" />} */}
          </div>

          <div className="right">
            {/* <NotificationIcon /> */}

            <Spacer width={md ? 25 : 10} />

            <div className="avatar">
              <img src={profile?.profilePicture ?? SellerAvatar} alt="profile" />
            </div>

            {!md && (
              <div>
                <p> {user.business_name}</p>
              </div>
            )}
            {!isLogged && !md && (
              <>
                <Spacer width={10} />
                <Link to={LOGIN} className="link">
                  Sign in / Register
                </Link>
              </>
            )}
          </div>
        </div>

        {md && <SideBar side={side} setSide={setSide} />}
      </TopNavBarCon>
      {!profile?.status && (
        <StickyNotification className="">Your Seller Account is yet to be verified by Madina</StickyNotification>
      )}
    </>
  );
};
