import { styled } from '@mui/material';
import { COLORS } from '../../../theme/color';
import { breakpoints, themes } from '../../../theme/theme';

export const SellerWalletActionIcon = styled('div')`
  background: ${COLORS.MainBlue};
  width: 42px;
  height: 42px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    fill: ${COLORS.White};
  }
`;

export const SellerWalletAction = styled('div')`
  display: grid;
  align-items: center;
  gap: 10px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  p {
    font-weight: 600;
    font-size: 13px;
  }
`;

export const SellerWalletActions = styled('div')`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: ${breakpoints.sm}px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;
  }
`;

export const SellerWalletHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${breakpoints.sm}px) {
    display: block;
  }
  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
  }
`;

export const SellerWalletStatsCon = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.8%;
  overflow-x: scroll;
`;

export const SellerWalletStatsCarousel = styled('div')`
  .carousel-con {
    width: 100%;
    min-height: 14rem;
  }

  .react-multi-carousel-dot-list {
    bottom: 0;
  }

  .react-multi-carousel-dot button {
    border-color: #d9d9d9;
    background: #d9d9d9;
  }

  .react-multi-carousel-dot--active button {
    background: ${themes.palette.primary.main};
    border-color: ${themes.palette.primary.main};
  }
`;

export const SellerWalletSubheader = styled('div')`
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: ${COLORS.MainBlack};
  padding: 16px 0;
  border-bottom: 1px solid #ededed;
  margin-bottom: 24px;
`;

export const SellerWalletCon = styled('div')`
  background: ${COLORS.White};
  padding: 2rem 2rem 2.5rem;
  display: flex;
  flex-direction: column;

  @media (max-width: ${breakpoints.sm}px) {
    padding: 5vw 5vw;
  }
`;

export const WalletAction = styled('div')``;
