import { useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '../Button/Button';
import { Divider } from '../Divider/Divider';
import { InputField } from '../InputField/InputField';
import {
  FormCON,
  ImageCON,
  RemoveBtnExtraStyles,
  SettingsInfoCON,
  UploadBtnExtraStyles,
  InputCON,
  InputExtraStyles,
  DeleteAccountCON,
  SubmitButtonCON,
  DeleteButtonExtraStyles,
  CoverImageCON,
  InputDisabledExtraStyles,
  underlineButton
} from './style';
import { Modal } from '../../molecules';
import UploadPicture from './UploadPicture';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userAtom, userIsLoggedIn, userRoleAtom } from '../../../store/atoms/userAtom';
import SellerAvatar from '../../../assets/images/seller-avatar.jpeg';
import { useMutationHook } from '../../../network/mutations/useMutationHook';
import { deleteAccount, getProfile } from '../../../network/services/profile';
import { TogglePassword } from '../../../pages/Auth/styles';
import { HidePassword, ShowPassword } from '../../../assets/Icons';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../network/services/auth';
import { updateProfile, uploadSellerLogo } from '../../../network/services/users';
import { Spacer } from '@chakra-ui/react';
import { useQuery } from 'react-query';

export const SettingsInformation = () => {
  const { data: profileData } = useQuery(['get-profile'], getProfile);

  const profile = useMemo(() => profileData?.data ?? {}, [profileData]);
  const [openUploadSellerLogoModal, setOpenUploadSellerLogoModal] = useState<boolean>(false);
  const [openUploadCoverImageModal, setOpenUploadCoverImageModal] = useState<boolean>(false);
  const [openDeleteAccountModal, setOpenDeleteAccountModal] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const user = useRecoilValue(userAtom);
  const [_, setUser] = useRecoilState(userAtom);
  const businessNameRef = useRef<HTMLInputElement>(null);
  const businessDescRef = useRef<HTMLInputElement>(null);
  const cacRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const [isLogged, setUserStatus] = useRecoilState(userIsLoggedIn);
  const [userRole, setUserRole] = useRecoilState(userRoleAtom);

  const { mutateAsync, isLoading } = useMutationHook(deleteAccount, 'DELETE_ACCOUNT');
  const { mutateAsync: mutateAsyncLogOut } = useMutationHook(logout, 'LOGOUT');
  const { mutateAsync: uploadLogo, isLoading: isUploadingLogo } = useMutationHook(
    uploadSellerLogo,
    'UPLOAD_SELLER_LOGO'
  );
  const { mutateAsync: updateProfileMutate, isLoading: isUpdatingProfile } = useMutationHook(
    updateProfile,
    'UPDATE_PROFILE'
  );

  const [uploadedLogo, setUploadedLogo] = useState<string | null>(null);
  const [uploadedCoverImage, setUploadedCoverImage] = useState<string | null>(null);
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [coverFile, setCoverFile] = useState<File | null>(null);

  useEffect(() => {
    if (profile) {
      const setSellerDetails = () => {
        businessNameRef.current!.value = profile.business_name || '';
        emailRef.current!.value = profile.email || '';
        phoneNumberRef.current!.value = profile.phoneNumber || '';
        businessDescRef.current!.value = profile.businessDescription || '';
        cacRef.current!.value = profile.cac || '';
      };
      setSellerDetails();
    }
  }, [profile]);

  const onConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  const [showPassword, setShowPassword] = useState<'text' | 'password'>('password');

  const onTogglePassword = () => {
    if (showPassword === 'text') return setShowPassword('password');
    setShowPassword('text');
  };

  const onDeleteAccountSuccess = async () => {
    try {
      await mutateAsyncLogOut(undefined);
    } catch (error) {
      console.log(error);
    }
    setUserRole('');
    setUserStatus(false);

    sessionStorage.clear();
    userRole === 'admin' && navigate('/admin');
    userRole === 'seller' && navigate('/');
  };

  const onDeleteAccount = async () => {
    try {
      const deleteAccount: any = await mutateAsync({ password: confirmPassword });
      console.log('deleteAccount', deleteAccount);
      if (deleteAccount.status === 'success') {
        setOpenDeleteAccountModal(false);
        onDeleteAccountSuccess();
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleUploadLogo = (file: File) => {
    setUploadedLogo(URL.createObjectURL(file));
    setLogoFile(file);
    setOpenUploadSellerLogoModal(false);
  };

  const handleUploadCoverImage = (file: File) => {
    setUploadedCoverImage(URL.createObjectURL(file));
    setCoverFile(file);
    setOpenUploadCoverImageModal(false);
  };

  const handleSubmitChanges = async () => {
    const formData = new FormData();
    if (logoFile) formData.append('brandLogo', logoFile);
    if (coverFile) formData.append('brandImage', coverFile);

    formData.append('businessDescription', businessDescRef.current?.value || '');
    formData.append('cac', cacRef.current?.value || '');

    // Log formData keys and values for verification

    Array.from(formData.entries()).forEach(([key, value]) => {
      console.log(`${key}:`, value);
    });

    try {
      const data = await updateProfileMutate(formData);
      if (data.status.toLowerCase() === 'success') {
        setUser((prev: any) => ({
          ...prev,
          profilePicture: data.data.profilePicture,
          coverImage: data.data.coverImage,
          business_name: data.data.business_name,
          businessDescription: data.data.businessDescription,
          email: data.data.email,
          phoneNumber: data.data.phoneNumber,
          cac: data.data.cac
        }));
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const disabled = !confirmPassword;

  return (
    <SettingsInfoCON>
      <div className="grid justify-end">
        {' '}
        <Button extraStyles={UploadBtnExtraStyles} isLoading={isUpdatingProfile} onClick={handleSubmitChanges}>
          Update
        </Button>
      </div>
      <ImageCON>
        <span className="mb-2">Brand Logo</span>
        <div>
          <img src={uploadedLogo ?? profile?.profilePicture ?? SellerAvatar} alt="" />
          <Button
            onClick={() => setOpenUploadSellerLogoModal(!openUploadSellerLogoModal)}
            extraStyles={underlineButton}>
            Change Brand Logo
          </Button>
        </div>
      </ImageCON>
      <Spacer height="24px" />
      <CoverImageCON>
        <span className="mb-2">Cover Image</span>
        <div>
          <img src={uploadedCoverImage ?? profile.brandImage ?? SellerAvatar} alt="" />
          <Button
            onClick={() => setOpenUploadCoverImageModal(!openUploadCoverImageModal)}
            extraStyles={underlineButton}>
            Change Cover Image
          </Button>
        </div>
      </CoverImageCON>
      <FormCON>
        <p className="text-medium text-red-500">
          <span>Note: </span>The fields in red cannot be changed
        </p>
        <InputCON>
          <span>Business Name</span>
          <InputField inputRef={businessNameRef} disabled extraStyles={InputDisabledExtraStyles} name="name" />
        </InputCON>
        <InputCON>
          <span>Business Description</span>
          <InputField inputRef={businessDescRef} extraStyles={InputExtraStyles} name="businessDescription" />
        </InputCON>
        <InputCON>
          <span>Email Address</span>
          <InputField inputRef={emailRef} disabled extraStyles={InputDisabledExtraStyles} name="emailAddress" />
        </InputCON>
        <InputCON>
          <span>Phone Number</span>
          <InputField inputRef={phoneNumberRef} disabled extraStyles={InputDisabledExtraStyles} name="phoneNumber" />
        </InputCON>
        <InputCON>
          <span>CAC Reg No.</span>
          <InputField inputRef={cacRef} extraStyles={InputExtraStyles} name="businessDescription" />
        </InputCON>
      </FormCON>

      <DeleteAccountCON>
        <Button extraStyles={DeleteButtonExtraStyles} onClick={() => setOpenDeleteAccountModal(true)}>
          Delete Account
        </Button>
        <p>By deleting your account, you lose all data</p>
      </DeleteAccountCON>
      <Divider />

      {openUploadSellerLogoModal && (
        <Modal title="Change Image" maxWidth="44rem" onModalClose={() => setOpenUploadSellerLogoModal(false)}>
          <UploadPicture
            setOpenUploadSellerLogoModal={setOpenUploadSellerLogoModal}
            onUpload={handleUploadLogo}
            isLoading={isUploadingLogo}
          />
        </Modal>
      )}
      {openUploadCoverImageModal && (
        <Modal title="Change Cover Image" maxWidth="44rem" onModalClose={() => setOpenUploadCoverImageModal(false)}>
          <UploadPicture
            setOpenUploadSellerLogoModal={setOpenUploadCoverImageModal}
            onUpload={handleUploadCoverImage}
          />
        </Modal>
      )}
      {openDeleteAccountModal && (
        <Modal title="Delete Account" maxWidth="44rem" onModalClose={() => setOpenDeleteAccountModal(false)}>
          <div>
            <p>Enter your password to confirm your account deletion.</p>
            <InputCON>
              <span>Password</span>
              <InputField
                type={showPassword}
                name="confirmPassword"
                onChange={onConfirmPasswordChange}
                placeholder="enter your password"
                extraStyles={InputExtraStyles}
                required={true}
                action={
                  <TogglePassword onClick={onTogglePassword}>
                    {showPassword !== 'text' ? <ShowPassword /> : <HidePassword />}
                  </TogglePassword>
                }
                autoComplete="new-password"
              />
            </InputCON>
            <SubmitButtonCON>
              <Button disabled={disabled || isLoading} isLoading={isLoading} onClick={onDeleteAccount}>
                Delete Account
              </Button>
            </SubmitButtonCON>
          </div>
        </Modal>
      )}
    </SettingsInfoCON>
  );
};
